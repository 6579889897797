import { NavLink } from "react-router-dom";
import importAllImages from "../../../utils/imageLoader";
import ResponsiveImage from "../../../utils/responsiveImage";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import SvgIcon from "../../../utils/svgIcon";


const images = importAllImages(require.context("./", false, /\.(png|webp|jpe?g|svg)$/));

const navLinks = [
  { to: "/clients", translationKey: "header.clients", defaultText: "Для клиентов" },
  { to: "/demo", translationKey: "header.tests", defaultText: "Тесты" },
  { to: "/projects", translationKey: "header.projects", defaultText: "Проекты" },
  { to: "/team", translationKey: "header.team", defaultText: "Команда" },
  { to: "/news", translationKey: "header.news", defaultText: "Статьи" },
];

const languages = [
  { code: "en", label: "en" },
  { code: "ru", label: "ru" },
  { code: "cn", label: "cn" },
];

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const burgerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !burgerRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = (event) => {
    event.stopPropagation();
    setMenuOpen((prev) => !prev);
  };
  // const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const changeLanguage = async (language) => {
    // setCurrentLanguage(language);

    // try {
    //   await axios.post("/api/set-language", { language });
    //   console.log("Language updated on server:", language);
    // } catch (error) {
    //   console.error("Error updating language on server:", error);
    // }
  };


  return (
    <header className="header">
      <div className="container">
        <div className="header__container">
          <NavLink to="/">
            <ResponsiveImage
              src={images["logo.png"]}
              retinaSrc={images["logo@2x.png"]}
              webpSrc={images["logo.webp"]}
              retinaWebpSrc={images["logo@2x.webp"]}
              alt="Logo"
            />
          </NavLink>
          <button
            ref={burgerRef}
            className={`header__burger ${menuOpen ? "burger--open" : ""}`}
            onClick={toggleMenu}
            aria-label="Toggle menu"
          >
            <span></span>
          </button>


          <nav
            ref={menuRef}
            className={`nav ${menuOpen ? "nav--open" : ""}`}>
            <ul>
              {navLinks.map((link) => (
                <li key={link.to}>
                  <NavLink
                    to={link.to}
                    className={({ isActive }) => (isActive ? "current" : "")}
                    onClick={() => setMenuOpen(false)}
                  >
                    <SvgIcon id="header_stars" width={16} height={16} />
                    {link.defaultText}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
          <ul className="header__lang">
            {languages.map((lang) => (
              <li key={lang.code}>
                <button
                  type="button"
                  // className={currentLanguage === lang.code ? "current" : ""}
                  onClick={() => changeLanguage(lang.code)}
                >
                  {lang.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
