import axios from "axios";
import { createContext, useCallback, useContext, useEffect, useState } from "react";

export const TokenContext = createContext();

export const TokenProvider = ({ children }) => {
  const _apiBase = "https://back.prestable.beautyscan.ru";

  const [tempToken, setTempToken] = useState(localStorage.getItem("tempToken"));

  const fetchTempToken = useCallback(async () => {
    try {
      const res = await axios.get(`${_apiBase}/users/temp_user_create/`);
      const token = res.data?.temp_token;

      if (token) {
        setTempToken(token);
        localStorage.setItem("tempToken", token);
        return token;
      }
    } catch (e) {
      console.error("Failed to fetch temp token", e);
    }
  }, []);

  useEffect(() => {
    if (!tempToken) {
      fetchTempToken();
    }
  }, [])

  return (
    <TokenContext.Provider value={{
      tempToken, fetchTempToken, setTempToken
    }} >
      {children}
    </TokenContext.Provider>
  )
}

export const useTokenContext = () => {
  return useContext(TokenContext)
}