import React from "react";

const ResponsiveImage = ({
  src, // PNG или JPEG для 1x
  retinaSrc, // PNG или JPEG для 2x
  webpSrc, // WebP для 1x
  retinaWebpSrc, // WebP для 2x
  alt,
  ...props
}) => {
  return (
    <picture>
      {/* Источник для WebP */}
      {webpSrc && retinaWebpSrc && (
        <source
          type="image/webp"
          srcSet={`${webpSrc} 1x, ${retinaWebpSrc} 2x`}
        />
      )}
      {src && retinaSrc && (
        <img
          src={src}
          srcSet={retinaSrc}
          alt={alt || "Image"}
          {...props}
        />
      )}
    </picture>
  );
};

export default ResponsiveImage;
