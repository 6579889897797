import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/app/App';
import reportWebVitals from './reportWebVitals';
import "./styles/styles.scss";
import { TokenProvider } from './providers/tokenProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <TokenProvider>
      <App />
    </TokenProvider>
  // </React.StrictMode>
);

reportWebVitals();
