import { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import gsap from "gsap";
import SvgIcon from "../../../utils/svgIcon";
import useSdService from "../../../services/scandermService";
import Spinner from "../spinner/spinner";
import ErrorScreen from "../../error/error";

const testStages = {
  face: [
    { name: "Загрузка фото", path: "/photo/face" },
    { name: "Анкета", path: "/survey/face" },
    { name: "Результаты", path: "/results/face" },
  ],
  nails: [
    { name: "Загрузка фото", path: "/photo/nails" },
    { name: "Анкета", path: "/survey/nails" },
    { name: "Результаты", path: "/results/nails" },
  ],
  // skin: [
  //   { name: "Загрузка фото", path: "/photo/skin" },
  //   { name: "Анкета", path: "/survey/skin" },
  //   { name: "Результаты", path: "/results/skin" },
  // ],
  // hair: [
  //   { name: "Загрузка фото", path: "/photo/hair" },
  //   { name: "Анкета", path: "/survey/hair" },
  //   { name: "Результаты", path: "/results/hair" },
  // ],
  // makeup: [
  //   { name: "Загрузка фото", path: "/photo/makeup" },
  //   { name: "Анкета", path: "/survey/makeup" },
  //   { name: "Результаты", path: "/results/makeup" },
  // ],
};

const testTitles = {
  face: "Лицо",
  nails: "Ногти",
  skin: "Кожа",
  hair: "Волосы",
  makeup: "Макияж",
};

const DashboardBlock = ({ type }) => {
  const [openTest, setOpenTest] = useState(type);
  const navigate = useNavigate();
  const asideRef = useRef(null);
  const itemsRef = useRef([]);
  const stagesRefs = useRef({});
  const { startNewTest } = useSdService()

  useEffect(() => {
    gsap.fromTo(
      asideRef.current,
      { x: "-100%", opacity: 0 },
      { x: "0%", opacity: 1, duration: 0.8, ease: "power3.out" }
    );

    gsap.fromTo(
      itemsRef.current,
      { y: 20, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
        stagger: 0.2,
        delay: 0.5,
        ease: "power3.out",
      }
    );

    if (openTest && stagesRefs.current[openTest]) {
      gsap.set(stagesRefs.current[openTest], { height: "auto", opacity: 1 });
    }
  }, []);

  const toggleTest = async (testType) => {
    try {
      const res = await startNewTest(testType)
      if (res) {
        if (openTest === testType) {
          gsap.to(stagesRefs.current[testType], {
            height: 0,
            opacity: 0,
            duration: 0.3,
            ease: "power3.inOut",
            onComplete: () => setOpenTest(null),
          });
        } else {
          if (openTest && stagesRefs.current[openTest]) {
            gsap.to(stagesRefs.current[openTest], {
              height: 0,
              opacity: 0,
              duration: 0.3,
              ease: "power3.inOut",
            });
          }

          setOpenTest(testType);
          gsap.fromTo(
            stagesRefs.current[testType],
            { height: 0, opacity: 0 },
            { height: "auto", opacity: 1, duration: 0.3, ease: "power3.inOut" }
          );

          navigate(`/photo/${testType}`);
        }
      }
    } catch (e) { }
  };

  return (
    <aside className="dashboard__content" ref={asideRef}>
      <nav>
        <ul className="dashboard__tests">
          {Object.keys(testStages).map((testType, index) => (
            <li
              key={testType}
              className={`dashboard__item ${testType === type ? "current" : ""}`}
              ref={(el) => (itemsRef.current[index] = el)}
            >
              <button
                className={`dashboard__toggle ${testType === type ? "current" : ""}`}
                onClick={() => toggleTest(testType)}
                aria-expanded={openTest === testType}
              >
                {testTitles[testType]}
                <SvgIcon id="bird" width={10} height={5} />
              </button>

              <ul
                className="dashboard__stages"
                ref={(el) => (stagesRefs.current[testType] = el)}
                style={{
                  overflow: "hidden",
                }}
              >
                {testStages[testType].map((stage, index) => (
                  <li key={index}>
                    <NavLink
                      to={stage.path}
                      className={({ isActive }) => (isActive ? "active" : "")}
                      onClick={(e) => {
                        const target = e.target;
                        if (!target.classList.contains("active") || !target.classList.contains("done")) {
                          e.preventDefault();
                        }
                      }}
                    >
                      {stage.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
};

export default DashboardBlock;
