import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "../content/header/header";
import Footer from "../content/footer/footer";
import Spinner from "../content/spinner/spinner";
import PhotoPage from "../../pages/PhotoPage.js";

const Tests = lazy(() => import("../../pages/TestsPage.js"));

function App() {
  return (
    <Router basename="/">
      <div className="App">
        <div className="wrapper">
          <h1 className="visually-hidden">Scanderm Solutions</h1>
          <Header />
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route path="/demo" element={<Tests />} />
              <Route path="/photo/:type" element={<PhotoPage />} />
            </Routes>
          </Suspense>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
