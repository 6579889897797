import React, { useEffect, useRef, useState } from "react";
import Modal from "../../modal/modal";
import useSdService from "../../../../services/scandermService";
import { useParams } from "react-router-dom";
import SvgIcon from "../../../../utils/svgIcon";

const isMobileDevice = () => /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

const MAX_FILE_SIZE = 10 * 1024 * 1024;
const ALLOWED_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/webp"];
const MIN_WIDTH = 300;
const MIN_HEIGHT = 300;
const MAX_WIDTH = 5000;
const MAX_HEIGHT = 5000;

const loadImage = (dataURL) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = dataURL;
  });
};

const getBase64Size = (base64) => {
  const base64Str = base64.split(",")[1] || "";
  return (base64Str.length * 3) / 4;
};

const PhotoModal = ({ isOpen, onClose, onSavePhoto, initialPhoto = null }) => {
  const { type } = useParams();
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const fileInputRef = useRef(null);

  const [photoData, setPhotoData] = useState(null);
  const [isUploadedPhoto, setIsUploadedPhoto] = useState(false);

  const [validationError, setValidationError] = useState("");
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const { photoUpload } = useSdService();

  useEffect(() => {
    if (initialPhoto) {
      setPhotoData(initialPhoto);
      setIsUploadedPhoto(true);
    } else if (isOpen && !isMobileDevice()) {
      enableWebcam();
      setPhotoData(null);
      setIsUploadedPhoto(false);
    }
    return () => {
      disableWebcam();
    };
  }, [isOpen, initialPhoto]);

  useEffect(() => {
    if (validationError) {
      setIsErrorVisible(true);
      const fadeOutTimer = setTimeout(() => {
        setIsErrorVisible(false);
        const clearErrorTimer = setTimeout(() => {
          setValidationError("");
        }, 300);
        return () => clearTimeout(clearErrorTimer);
      }, 5000);
      return () => clearTimeout(fadeOutTimer);
    }
  }, [validationError]);

  const enableWebcam = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      const video = videoRef.current;
      video.srcObject = stream;
    } catch (error) {
      console.warn("Webcam access denied or not supported");
    }
  };

  const disableWebcam = () => {
    const video = videoRef.current;
    if (video && video.srcObject) {
      const stream = video.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      video.srcObject = null;
    }
  };

  const handleClose = () => {
    setValidationError("");
    setIsErrorVisible(false);
    disableWebcam();
    onClose();
  };

  const handleTakePhoto = () => {
    if (isMobileDevice()) {
      fileInputRef.current.click();
    } else {
      capturePhoto();
    }
  };

  const capturePhoto = () => {
    if (!videoRef.current || !canvasRef.current) return;
    const canvas = canvasRef.current;
    const video = videoRef.current;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const image = canvas.toDataURL("image/png");
    setPhotoData(image);
    setIsUploadedPhoto(false);
    setValidationError("");
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPhotoData(reader.result);
      setIsUploadedPhoto(true);
      setValidationError("");
      fileInputRef.current.value = "";
    };
    reader.readAsDataURL(file);
  };

  const handleRetakePhoto = () => {
    setPhotoData(null);
    setValidationError("");
    if (isUploadedPhoto || isMobileDevice()) {
      fileInputRef.current.click();
    } else {
      enableWebcam();
    }
  };

  const handleDismissError = () => {
    setIsErrorVisible(false);
    setTimeout(() => {
      setValidationError("");
    }, 300);
  };

  const validateCurrentPhoto = async () => {
    setValidationError("");

    const inputFile = fileInputRef.current?.files?.[0] ?? null;
    if (inputFile) {
      if (!ALLOWED_TYPES.includes(inputFile.type)) {
        return "Допустимые форматы: JPEG, JPG, PNG, WEBP";
      }
      if (inputFile.size > MAX_FILE_SIZE) {
        return "Размер файла не может превышать 10MB";
      }
    } else {
      const mimeMatch = photoData?.match(/^data:(image\/[a-zA-Z]+);base64,/);
      if (!mimeMatch) {
        return "Неверный формат изображения";
      }
      const mimeType = mimeMatch[1];
      if (!ALLOWED_TYPES.includes(mimeType)) {
        return "Допустимые форматы: JPEG, JPG, PNG, WEBP";
      }
      const approximateBytes = getBase64Size(photoData);
      if (approximateBytes > MAX_FILE_SIZE) {
        return "Размер файла не может превышать 10MB (приблизительно)";
      }
    }

    try {
      const img = await loadImage(photoData);
      const { width, height } = img;
      if (width < MIN_WIDTH || height < MIN_HEIGHT) {
        return `Минимальное разрешение: ${MIN_WIDTH}x${MIN_HEIGHT} пикселей`;
      }
      if (width > MAX_WIDTH || height > MAX_HEIGHT) {
        return `Максимальное разрешение: ${MAX_WIDTH}x${MAX_HEIGHT} пикселей`;
      }
    } catch (error) {
      return "Не удалось загрузить изображение для проверки";
    }
    return "";
  };

  const handleSavePhoto = async () => {
    if (!photoData) return;
    const errorMessage = await validateCurrentPhoto();
    if (errorMessage) {
      setValidationError(errorMessage);
      return;
    }
    onSavePhoto(photoData);
    handleClose();
    try {
      await photoUpload(type, photoData);
    } catch (e) {
      console.warn("Ошибка при загрузке фото:", e);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className="photo__modal">
        {photoData ? (
          <div className="photo__preview">
            <h3>{isUploadedPhoto ? "Загруженное фото" : "Снятое фото"}</h3>
            <img src={photoData} alt="Captured" className="photo__image" />
            
            {validationError && (
              <span
                className={`photo__error ${
                  isErrorVisible ? "photo__error--visible" : ""
                }`}
              >
                <SvgIcon id="close" width={10} height={10} />
                {validationError}
                <button type="button" onClick={handleDismissError}>
                  <SvgIcon id="close" width={14} height={14} />
                </button>
              </span>
            )}

            <div>
              <button onClick={handleSavePhoto} className="btn">
                Использовать это фото
              </button>
              <button onClick={handleRetakePhoto} className="btn btn--white">
                {isUploadedPhoto ? "Выбрать другое фото" : "Переснять"}
              </button>
            </div>
          </div>
        ) : (
          <div className="photo__area">
            {!isMobileDevice() && (
              <video
                ref={videoRef}
                autoPlay
                playsInline
                style={{ width: "100%", height: "100%" }}
              />
            )}
            <div className="photo__capture">
              <button
                onClick={handleTakePhoto}
                className="photo__capture-button"
              >
                Сделать фото
              </button>
            </div>
          </div>
        )}
      </div>

      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        capture="environment"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />

      <canvas ref={canvasRef} style={{ display: "none" }} />
    </Modal>
  );
};

export default PhotoModal;
