const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__container">
          <div>
            <span>Решение подготовлено компаниями ООО "Скандерм Про"</span>
            <p>Диагностика не является медицинским обследованием, а также не заменяет консультацию профессионального эксперта. Результат виртуальной диагностики не является медицинским диагнозом.</p>
          </div>
          <span>© Все права защищены, 2025</span>
        </div>
      </div>
    </footer>
  )
}

export default Footer