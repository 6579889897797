import { useState } from "react";
import { NavLink } from "react-router-dom";
import PhotoModal from "../photoModal/photoModal";
import SvgIcon from "../../../../utils/svgIcon";
import importAllImages from "../../../../utils/imageLoader";

const isMobileDevice = () => {
  return /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
};

const images = importAllImages(require.context("./", false, /\.(png|webp|jpe?g|svg)$/));

const photoRules = [
  {
    type: "face",
    title: "Лицо",
    tips: [
      { icon: "smile", text: "Без улыбки" },
      { icon: "makeup", text: "Без макияжа и аксессуаров" },
      { icon: "light", text: "С хорошим освещением" },
      { icon: "incline", text: "Без наклона головы" },
    ],
  },
  {
    type: "nails",
    title: "Ногти",
    tips: [
      { icon: "nails_makeup", text: "Без маникюра" },
      { icon: "varnish", text: "Без лакового покрытия" },
      { icon: "light", text: "С хорошим освещением" },
    ],
  },
];

const PhotoBlock = ({ type }) => {
  const [isCameraModalOpen, setCameraModalOpen] = useState(false);
  const [capturedPhoto, setCapturedPhoto] = useState(null);
  const [isAnalysisMode, setIsAnalysisMode] = useState(false);
  const rule = photoRules.find((rule) => rule.type === type);

  const handleTakePhotoClick = () => {
    setCapturedPhoto(null);
    if (isMobileDevice()) {
      document.getElementById("file").click();
    } else {
      setCameraModalOpen(true);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const photo = e.target.result;
        setCapturedPhoto(photo);
        setCameraModalOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSavePhoto = async (photo) => {
    setCapturedPhoto(photo);
    setIsAnalysisMode(true);
  };

  const generateImageName = (suffix) => `${type}_ex${suffix}`;

  return (
    <section className="photo">
      <div className="container">
        <div className="photo__container">
          <div className="photo__top">
            {isAnalysisMode ? (
              <></>
            ) : (
              <NavLink to="/demo">
                <SvgIcon id="bird" width={12} height={16} />
                Сменить тест
              </NavLink>
            )}
            <h2>{isAnalysisMode ? "Анализ фото" : `Загрузка фото: ${rule.title}`}</h2>
          </div>
          {isAnalysisMode ? (
            <div className="photo__analysis">
              <img src={capturedPhoto} alt="Выбранное фото" className="photo__image" />
              <span>
                <SvgIcon id="header_stars" width={16} height={16} />
                Проводим анализ...
              </span>
            </div>
          ) : (
            <div className={`photo__examples photo__${type}`}>
              <img
                src={images[generateImageName(".png")]}
                srcSet={`
                  ${images[generateImageName(".png")]} 1x,
                  ${images[generateImageName("@2x.png")]} 2x
                `}
                alt={`Пример для ${type}`}
              />
              <h3>Сделайте или загрузите фото</h3>
              <ul className="photo__tips">
                {rule.tips.map((tip, index) => (
                  <li key={index}>
                    <span>
                      <SvgIcon id={`${tip.icon}`} width={20} height={20} />
                    </span>
                    {tip.text}
                  </li>
                ))}
              </ul>
              <div className="photo__actions">
                <button onClick={handleTakePhotoClick} className="btn">
                  Сделать фото
                  <SvgIcon id="photo" width={16} height={16} />
                </button>
                <label className="photo__upload btn btn--white">
                  Загрузить фото
                  <input
                    id="file"
                    name="file"
                    type="file"
                    capture="environment"
                    accept="image/jpeg,image/jpg,image/png,image/webp"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <SvgIcon id="download" width={16} height={16} />
                </label>
              </div>
              <p className="photo__description">png, jpg, webp максимум до 10 Mb</p>
            </div>
          )}
        </div>
      </div>
      <PhotoModal
        isOpen={isCameraModalOpen}
        onClose={() => setCameraModalOpen(false)}
        onSavePhoto={handleSavePhoto}
        initialPhoto={capturedPhoto}
      />
    </section>
  );
};

export default PhotoBlock;

