import ScandermLogo from "../footer/footer_logo@2x.webp"


const Spinner = () => {
  return (
    <div className="spinner">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{ margin: "0 auto", display: "block" }}
        width="150px"
        height="150px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <defs>
          <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: "#8e44ad", stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: "#3498db", stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <circle
          cx="50"
          cy="50"
          r="45"
          stroke="url(#gradient1)"
          strokeWidth="10"
          fill="none"
          strokeDasharray="283"
          strokeDashoffset="75"
        >
          <animate
            attributeName="stroke-dashoffset"
            values="75; 0; 75"
            dur="1.5s"
            repeatCount="indefinite"
          />
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 50 50"
            to="360 50 50"
            dur="2s"
            repeatCount="indefinite"
          />
        </circle>
        <image
          href={ScandermLogo}
          x="35"
          y="35"
          width="30"
          height="30"
          preserveAspectRatio="xMidYMid slice"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 50 50"
            to="360 50 50"
            dur="2s"
            repeatCount="indefinite"
          />
        </image>
      </svg>
      <p className="spinner__text">Ожидайте</p>
    </div>
  );
};

export default Spinner;
