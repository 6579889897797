import { useHttp } from "../hooks/httpHook";

const useSdService = () => {
  const { loading, error, errorText, request, clearError } = useHttp();
  const _apiBase = "https://back.prestable.beautyscan.ru";

  // new test
  const startNewTest = async (type) => {
    const res = await request(`${_apiBase}/${type}/start_new_test/`, "POST");
    return res?.data?.result;
  };

  // photo upload
  const photoUpload = async (type, photo) => {
    const res = await request(`${_apiBase}/${type}/photo_upload/`, "POST", {

      photo: photo
    })
    return res?.data?.result;
  }

  return {
    loading,
    error,
    errorText,
    clearError,
    startNewTest,
    photoUpload,
  }
}

export default useSdService