import { Helmet } from "react-helmet"
import PhotoBlock from "../components/content/photo/photoUpload/photo"
import { useParams } from "react-router-dom";
import DashboardBlock from "../components/content/dashboard/dashboard";

const PhotoPage = () => {
  const { type } = useParams();

  return (
    <>
      <Helmet>
        <meta name="description" content="загрузите фото для анализа" />
        <title>Анализ фото</title>
      </Helmet>
      <main>
        <div className="dashboard__wrapper">
          <DashboardBlock type={type} />
          <PhotoBlock type={type} />
        </div>
      </main>
    </>
  )
}

export default PhotoPage